import React, { useEffect, useState, useRef, useContext, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import sanityClient from "../client.js";
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
import WebViewer from "@pdftron/pdfjs-express-viewer";

import Lottie from "lottie-react";
import hexagonUpAnimation from "../animations/hex-arrange-in-v1-up-white.json";
import hexagonDownAnimation from "../animations/hex-arrange-in-v2-down-white.json";
import { FullScreenContext, FullScreenToggle } from "./FullScreenProvider.js";

const DownloadIcon = () => (
  <svg
    fill="#000000"
    height="16px"
    width="16px"
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 29.978 29.978"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      {" "}
      <g>
        {" "}
        <path d="M25.462,19.105v6.848H4.515v-6.848H0.489v8.861c0,1.111,0.9,2.012,2.016,2.012h24.967c1.115,0,2.016-0.9,2.016-2.012 v-8.861H25.462z"></path>{" "}
        <path d="M14.62,18.426l-5.764-6.965c0,0-0.877-0.828,0.074-0.828s3.248,0,3.248,0s0-0.557,0-1.416c0-2.449,0-6.906,0-8.723 c0,0-0.129-0.494,0.615-0.494c0.75,0,4.035,0,4.572,0c0.536,0,0.524,0.416,0.524,0.416c0,1.762,0,6.373,0,8.742 c0,0.768,0,1.266,0,1.266s1.842,0,2.998,0c1.154,0,0.285,0.867,0.285,0.867s-4.904,6.51-5.588,7.193 C15.092,18.979,14.62,18.426,14.62,18.426z"></path>{" "}
        <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
        <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g>{" "}
      </g>{" "}
    </g>
  </svg>
);

export default function PdfReadingPage({ callback, placeholderImage }) {
  const viewer = useRef(null);
  const [pdfLink, setPdfLink] = useState("");
  const [hasPdfLoadError, setHasPdfLoadError] = useState(false);

  const instance = useRef();

  const [data, setData] = useState(null);
  const { slug } = useParams();

  const hexagonDown = useRef();
  const hexagonUp = useRef();

  const { isFullScreen } = useContext(FullScreenContext);

  const downloadPdfAction = useCallback(async () => {
    const { docViewer } = instance.current;
    const fileData = await docViewer.getDocument().getFileData({});
    const arr = new Uint8Array(fileData);
    const blob = new Blob([arr], { type: 'application/pdf' });
    const downloadFile = (blob, fileName) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.append(link);
      link.click();
    };
    downloadFile(blob, (data && data.article[0].title || 'file') + ".pdf");
  }, [data, instance]);

  useEffect(() => {
    const eventListener = () => {
      if (instance.current) {
        instance.current.setFitMode(instance.current.FitMode.FitWidth);
      }
    };
    window.addEventListener("resize", eventListener);
    return () => {
      window.removeEventListener("resize", eventListener);
    };
  }, []);

  useEffect(() => {
    if (instance.current || !pdfLink) {
      return;
    }
    WebViewer(
      {
        path: "/pdfjsexpress",
        licenseKey: process.env.REACT_APP_PDFJS_LICENSE,
        initialDoc: pdfLink,
        disabledElements: ["toolsHeader", "header"],
      },
      viewer.current
    ).then((_instance) => {
      instance.current = _instance;
      _instance.setFitMode(_instance.FitMode.FitWidth);
      _instance.UI.addEventListener('loaderror', () => {
        setHasPdfLoadError(true);
        _instance.UI.closeDocument();
        document.querySelector('iframe#webviewer-1').style.display = 'none';
      });    
    });
  }, [pdfLink, data]);


  useEffect(() => {
    callback(true);
    hexagonDown.current && hexagonDown.current.playSegments([0, 50], true);
    hexagonUp.current && hexagonUp.current.playSegments([0, 50], true);

    sanityClient
      .fetch(
        `{
            "globals": *[_type == "globals"] {
                background {
                    asset-> {
                        _id,
                        url,
                    },
                },
            },
            "article": *[slug.current == $slug] {
                title,
                slug,
                metaTitle,
                metaDescription,
                metaKeywords,
                postDate,
                image {
                    asset-> {
                        _id,
                        url
                    },
                    caption
                },
                body[]{
                    ...,
                    markDefs[]{
                        ...,
                        _type == "internalLink" => {
                            "slug": @.reference->slug
                        }
                    }
                },
                bodymarkdown,
                usefulLinks
            },
        }`,
        { slug }
      )
      .then((data) => {
        setData(data);
        setPdfLink(data.article[0].bodymarkdown);
      })
      .catch(console.error);
  }, [slug]);

  if (!data || data.article.length === 0) {
    return <span></span>
  }

  return (
    <>
      {isFullScreen && <FullScreenToggle mountOnBody hideInDesktop />}
      {data && (
        <Helmet prioritizeSeoTags>
          <title data-rh="true">
            {data.article[0].metaTitle
              ? data.article[0].metaTitle
              : data.article[0].title}{" "}
            - Geometry
          </title>
          <meta
            property="og:title"
            content={`${
              data.article[0].metaTitle
                ? data.article[0].metaTitle
                : data.article[0].title
            } - Geometry`}
          />
          <meta
            property="og:image"
            content={`${
              data.article[0].image && data.article[0].image.asset
                ? data.article[0].image.asset.url
                : placeholderImage && placeholderImage.asset.url
            }`}
          />
          <meta
            name="description"
            content={truncateString(
              data.article[0].metaDescription
                ? data.article[0].metaDescription
                : "Building and investing in research-rich startups",
              152
            )}
            data-rh="true"
          />
          <meta
            property="og:description"
            content={truncateString(
              data.article[0].metaDescription
                ? data.article[0].metaDescription
                : "Building and investing in research-rich startups",
              200
            )}
            data-rh="true"
          />
          <meta property="twitter:card" content="summary" data-rh="true" />
          <meta
            property="twitter:creator"
            content="@__geometry__"
            data-rh="true"
          />
          <meta
            name="keywords"
            content={
              data.article[0].metaKeywords
                ? data.article[0].metaKeywords
                : "investing, research, web3, cryptography, defi, startup, scalability"
            }
            data-rh="true"
          />
        </Helmet>
      )}

      <div className="relative w-full h-full overflow-visible">
        <div className="fixed top-0 h-screen left-0 w-screen z-0">
          <Lottie
            lottieRef={hexagonDown}
            className="absolute scale-50 lg:scale-100"
            style={{ left: "-300px", width: "600px", top: "-200px" }}
            animationData={hexagonDownAnimation}
            autoplay={false}
            loop={false}
          />
          <Lottie
            lottieRef={hexagonUp}
            className="absolute scale-50 lg:scale-100"
            style={{ right: "-200px", width: "700px", bottom: "-300px" }}
            animationData={hexagonUpAnimation}
            autoplay={false}
            loop={false}
          />
        </div>
        <div
          className={`absolute w-full h-full left-0 top-0 overflow-hidden flex px-0 sm:pl-0`}
        >
          {data && !isFullScreen ? (
            <>
              <aside className="hidden lg:flex flex-col flex-none z-20 pb-10 lg:pb-20 w-52 fade-in">
                <div className="mb-6 flex-col">
                  {window.location.pathname.includes("/page/") ? (
                    <Link
                      to="/contact"
                      className="text-blue text-base flex items-center group hover:text-cyan transition-colors duration-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-chevron-compact-left text-blue group-hover:text-cyan transition-all duration-500"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
                        />
                      </svg>
                      Back
                    </Link>
                  ) : (
                    <Link
                      to="/notebook"
                      className="text-blue text-base flex items-center group hover:text-cyan transition-colors duration-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="currentColor"
                        className="bi bi-chevron-compact-left text-blue group-hover:text-cyan transition-all duration-500"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"
                        />
                      </svg>
                      Back to notebook
                    </Link>
                  )}
                </div>
                <h2 className="font-semibold text-lg text-blue mb-6">
                  {data.article[0].title}
                </h2>
                {data.article[0].usefulLinks ? (
                  <div className="mt-auto flex-col">
                    <h3 className="font-semibold text-lg text-cyan mt-6 mb-2">
                      Useful Links
                    </h3>
                    {data.article[0].usefulLinks.map((link, i) => (
                      <a
                        href={link.url}
                        className="font-light text-blue pl-8 text-base flex hover:text-cyan transition-colors duration-500"
                        key={i}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        {link.title}
                      </a>
                    ))}
                  </div>
                ) : null}
                
                <div onClick={downloadPdfAction}>
                  <span
                    className={`border-2 p-2 rounded-md bg-white z-[999] flex gap-2 text-base items-center cursor-pointer shadow-md hover:shadow-xl bottom-8 right-8 xl:bottom-16 xl:right-16`}
                  >
                    <span className='w-6 xl:w-4 flex justify-center'><DownloadIcon /></span>
                    Download PDF
                  </span>
                </div>
                
                <FullScreenToggle
                  containerClasses={"relative inset-0 mt-4"}
                ></FullScreenToggle>
              </aside>
              <div className="hidden lg:block w-0.5 h-full bg-cyan mx-10 xl:mx-16 z-20"></div>
            </>
          ) : null}
          {!hasPdfLoadError ? 
            <div
              className={`webviewer h-100 no-scroll overflow-y-auto overflow-x-hidden w-full xl:w-3/4 mx-auto`}
              ref={viewer}
            ></div> : 
            <div className='flex justify-center items-center flex-1 flex-col gap-4'>
              <div className='text-center'>There was an error loading the pdf. Download pdf file instead?</div>
                <div>
                  <a href={pdfLink} target="_blank">
                    <span
                      className={`border-2 p-2 rounded-md bg-white z-[999] flex gap-2 text-base items-center cursor-pointer shadow-md hover:shadow-xl bottom-8 right-8 xl:bottom-16 xl:right-16`}
                    >
                      <DownloadIcon />
                      Download PDF
                    </span>
                  </a>
                </div>
            </div>
          }
        </div>
      </div>
    </>
  );
}
