import React, { useState, useRef, createRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import Lottie from "lottie-react";
import articleAndCodeLibraryOutline from "../animations/articles-and-code-library-outline.json";
import hexagonUpAnimation from "../animations/hex-arrange-in-v3-up.json"
import hexagonDownAnimation from "../animations/hex-arrange-in-v5-down.json"
import { FullScreenContext } from '../components/FullScreenProvider.js';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {

    return builder.image(source);
}

const PdfViewerArticleCategories = ["Research Booklets"];
export default function ArticlePage({ callback, callback2, placeholderImage, metaTitle, metaDescription, metaKeywords, categories: categoriesWithoutAll }) {
    const animations = useRef([]);
    const [searchString, setSearchString] = useState('');
    const [searchForString, setSearchForString] = useState('');
    const [showResults, setShowResults] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);;
    const [articles, setArticles] = useState([]);
    const [pdfViewerArticles, setPdfViewerArticles] = useState([]);
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true);
    const [downloadingFile, setDownloadingFile] = useState('');

    const { isFullScreen, toggleFullScreen } = useContext(FullScreenContext);
    const scrollContainerRef = useRef();
    
    useEffect(() => {
        const eventListener = (e) => {
            let newScrollTop = scrollContainerRef.current.scrollTop + e.deltaY;
            if (scrollContainerRef.current && scrollContainerRef.current) {
                if (newScrollTop < 0) {
                    newScrollTop = 0;
                }
                scrollContainerRef.current.scroll({ top: newScrollTop, behaviour: 'smooth' });
            }
        };
        window.addEventListener('wheel', eventListener);
        scrollContainerRef.current.addEventListener('wheel', (e) => {
            e.preventDefault();
        });
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.removeProperty('overflow');
            window.removeEventListener('wheel', eventListener);
        };
    },[])
    useEffect(() => {
        const existingCategories = [...categoriesWithoutAll];
        const newCategory = {
            title: "All",
            articles: existingCategories.map(category => category.articles).flat().sort(function(a, b) {
                return (a.postDate < b.postDate) ? 1 : ((a.postDate > b.postDate) ? -1 : 0);
            })
        };
        existingCategories.unshift(newCategory);
        setCategories(existingCategories);
    }, [categoriesWithoutAll]);

    useEffect(()=>{
        if (isFullScreen) {
            toggleFullScreen();
        }
    }, []);

    useEffect(() => {
        if (categories) {
            const allPdfViewerCategories = categories.filter(category => PdfViewerArticleCategories.includes(category.title));
            const allPdfViewerArticles = allPdfViewerCategories.length ? allPdfViewerCategories.map(category => category.articles).flat() : [];
            setPdfViewerArticles(allPdfViewerArticles);
        }
    }, [categories]);

    const search = (event) => {
        event.preventDefault();

        if (!event.target.elements.search.value) {
            setSearchResults([]);
            setShowResults(false);
        } else {
            filterResults(searchString);
            ReactGA.event({
                category: 'Search Event',
                action: 'Search',
                label: searchString,
            })
        }
    }

    const filterResults = (text) => {

        setSearchString(text);
        setSearchForString(text);

        let search = text.replaceAll(' ', '|')
        var re = new RegExp(search, "i");
        let matches = [];

        const allArticles = categoriesWithoutAll ? categoriesWithoutAll.map(category => category.articles).flat() : [];
        allArticles.forEach(article => {
            if (article.title.match(re) || article.description.match(re)) {
                matches.push(article);
            }
        });
        setSearchResults(matches);
        setShowResults(true);
    }

    const downloadPdf = (slug) => {
        setDownloadingFile(slug);
        sanityClient
        .fetch(
            `{
                "article": *[slug.current == $slug] {
                    title,
                    slug,
                    bodymarkdown
                },
            }`,
            { slug }
        )
        .then(data => data.article[0].bodymarkdown)
        .then((fileUrl) => {
            return fetch(fileUrl, {})
        })
        .then( res => res.blob() )
        .then( blob => {
            const link = document.createElement ('a');
            link.href = URL.createObjectURL(blob) ;
            link.download = slug;
            document.body.append (link);
            link.click();
        })
        .catch(console.error)
        .finally(() => {
            setTimeout(() => {
                setDownloadingFile('');
            }, 1000);
        });
    };

    // const clearResults = () => {
    //     setSearchString('');
    //     setSearchForString('');
    //     setSearchResults([]);
    //     setShowResults(false);
    // }

    const showArticle = (article, i) => {
        const isPdfArticle = pdfViewerArticles.find(x => x.slug.current === article.slug.current);
        const articleLink = isPdfArticle ? `/notebook-pdf/${article.slug.current}` : `/notebook/${article.slug.current}`;
        return (<Link to={articleLink} key={article.slug.current} className="flex flex-row-full overflow-hidden mb-8 lg:px-10 group" onMouseEnter={() => build(i)} onMouseLeave={() => unBuild(i)}>
            <div className="hidden md:flex w-48 h-full relative justify-center items-center">
                {article.image ? (
                    <div className="relative">
                        <div className="notebook-article bg-cover bg-center bg-no-repeat" style={{ backgroundImage: `url(${article.image.asset.url}` }}>
                            {/* <img className="w-24 z-10" src={urlFor(article.image.asset).width(206).url()} alt="" /> */}
                        </div>
                        <div className="notebook-article__border absolute z-10 top-0 left-0 opacity-20"></div>
                    </div>
                ) : (
                    <img className="w-24 z-10" src={urlFor(placeholderImage.asset).width(206).url()} alt="" />
                )}
                <Lottie lottieRef={animations.current[i]} className="absolute top-0 w-full left-0 h-full z-20" animationData={articleAndCodeLibraryOutline} autoplay={false} loop={false} />
            </div>
            <div className="flex-1 h-auto pl-0 xl:pl-16">
                <h3 className="text-white text-2xl mb-0 font-opensans font-demibold group-hover:text-cyan transition-colors duration-500">{article.title}</h3>
                <small className="inline-block font-opensans font-light text-white mb-3">{new Date(article.postDate).toLocaleString("en-za", { year: 'numeric', month: 'long', day: 'numeric' })}</small>
                <p className="text-white text-base mb-2 font-opensans font-light">{article.description}</p>
                <span href={`/notebook/${article.slug.current}`} className="text-cyan text-xs uppercase font-opensans tracking-[8px] uppercase">Read more</span>
                {isPdfArticle && <span onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    downloadPdf(article.slug.current)}
                } className="text-cyan text-xs uppercase font-opensans tracking-[8px] uppercase ml-8">{downloadingFile === article.slug.current ? 'Downloading' : 'Download PDF'}</span>}
            </div>
        </Link>)
    }

    const showArticles = () => {
        if (showResults) {
            return (<div className="col-span-12 xl:col-span-10 2xl:col-span-8">
                {searchResults.length > 0 ? (
                    <>
                        <h1 className="text-2xl mb-5 text-white font-opensans">Search results for: "{searchForString}"</h1>
                        {searchResults.map((article, i) => (
                            showArticle(article, i)
                        ))}
                    </>
                ) : <h1 className="text-2xl mb-5 text-white font-opensans">No results found for: "{searchForString}"</h1>}
            </div>)
        }

        if (loading) {
            return (<div className="xl:col-span-10 col-span-12 2xl:col-span-8">
                <h1 className="text-2xl mb-5 text-center text-white font-clarikageo">Loading...</h1>
            </div>);
        }

        if (!articles.length && !loading) {
            return (<div className="xl:col-span-10 col-span-12 2xl:col-span-8">
                <h1 className="text-2xl mb-5 text-center text-white font-clarikageo">No articles found</h1>
            </div>);
        }

        return (<div className=" xl:col-span-10 col-span-12 2xl:col-span-8">
            {articles.map((article, i) => (
                showArticle(article, i)
            ))}
        </div>)
    }

    const build = function (i) {

        animations.current[i].current.setDirection(1);
        animations.current[i].current.setSpeed(2);
        animations.current[i].current.playSegments([0, 45], true);
    }

    const unBuild = function (i) {

        animations.current[i].current.setDirection(-1);
        animations.current[i].current.setSpeed(2);
        animations.current[i].current.playSegments([45, 0], true);
    }

    animations.current = articles.map((_, i) => animations.current[i] ?? createRef());

    const hexagonDown = useRef();
    const hexagonUp = useRef();

    useEffect(() => {
        callback(false);
        callback2(false);
        hexagonDown.current.playSegments([0, 50], true);
        hexagonUp.current.playSegments([0, 50], true);
    }, []);

    useEffect(() => {
        if (selectedCategory) {
            const category = categories.find(category => category.title === selectedCategory.title);
            const articles = category ? category.articles : [];
            setArticles(articles);
            if (articles.length) {
                setLoading(false);
            }
        } else {
            setArticles([]);
        }
    }, [selectedCategory, categories]);

    useEffect(() => {
        if (!selectedCategory && categories.length > 0) {
            setSelectedCategory(categories[0]);
        }
    }, [selectedCategory, categories]);

    const onCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title data-rh="true">{metaTitle ? metaTitle : "Notebook"} - Geometry</title>
                <meta name="description" content={truncateString(metaDescription ? metaDescription : "Building and investing in research-rich startups", 152)} data-rh="true" />
                <meta name="keywords" content={metaKeywords ? metaKeywords : "investing, research, web3, cryptography, defi, startup, scalability"} data-rh="true" />
            </Helmet>

            <div className="fixed top-0 h-screen left-0 w-screen z-0">
                <Lottie
                    lottieRef={hexagonDown}
                    className="absolute scale-50 lg:scale-100"
                    style={{ left: "-300px", width: "700px", top: "-150px" }}
                    animationData={hexagonDownAnimation}
                    autoplay={false}
                    loop={false}
                />
                <Lottie
                    lottieRef={hexagonUp}
                    className="absolute scale-50 lg:scale-100"
                    style={{ right: "-300px", width: "600px", bottom: "-300px" }}
                    animationData={hexagonUpAnimation}
                    autoplay={false}
                    loop={false}
                />
            </div>
            <div
                id="notebook-page-container"
                className="pt-16 md:pt-24 absolute w-full h-full flex flex-col lg:flex-row lg:items-center fade-in">
                <div className='block xl:hidden cursor-pointer z-50 absolute top-3 md:top-10 left-0' onClick={() => setShowSubMenu(!showSubMenu)}>
                    <div className='relative'>
                        <svg fill="#ffffff" height="24px" width="24px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150">
                            <g id="XMLID_240_">
                                <path id="XMLID_241_"
                                    d="M15,30h120c8.284,0,15-6.716,15-15s-6.716-15-15-15H15C6.716,0,0,6.716,0,15S6.716,30,15,30z" />
                                <path id="XMLID_242_"
                                    d="M135,60H15C6.716,60,0,66.716,0,75s6.716,15,15,15h120c8.284,0,15-6.716,15-15S143.284,60,135,60z" />
                                <path id="XMLID_243_"
                                    d="M135,120H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h120c8.284,0,15-6.716,15-15S143.284,120,135,120z" />
                            </g>
                        </svg>
                    </div>
                    <div className={`absolute top-8 left-0 bg-white transition-all duration-200 ${showSubMenu ? 'scale-x-1 scale-y-1 translate-y-0 translate-x-0' : 'scale-x-0 scale-y-0 -translate-x-1/2 -translate-y-1/2'}`}>
                        {
                            categories.map(category =>  
                                <div className={`font-opensans w-48 text-blue tracking-[4px] text-center cursor-pointer py-2 my-2 ${selectedCategory && selectedCategory.title === category.title && 'font-bold'}`} key={category.title} onClick={() => onCategoryClick(category)}>
                                    <span>{category.title}</span>
                                </div>
                            )
                        }
                    </div>
                </div>
                <form className="absolute pl-12 md:mt-8 lg:pl-0 top-0 right-0 lg:right-12 w-full lg:w-auto flex lg:justify-end justify-center" onSubmit={search}>
                    <input type="search" name="search" className="relative font-opensans font-light appearance-none outline-0 ring-0 focus:ring-0 text-white placeholder:text-white bg-white bg-opacity-10 w-full lg:w-64 p-3 pl-11 rounded-full" placeholder="Search..." value={searchString} onChange={e => setSearchString(e.target.value)} />
                    <div className="absolute w-5 left-16 lg:left-3 top-1/2 -translate-y-1/2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#FFFFFF" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z" /></svg>
                    </div>
                    <button type="submit" className="font-opensans font-light bg-white text-blue rounded-full px-3 ml-2">Search</button>
                    {/* {searchString &&
                        <button type="button" onClick={clearResults} className="font-opensans font-light bg-transparent text-blue ml-2">Reset</button>
                    } */}
                </form>


                <div ref={scrollContainerRef} className="relative w-full lg:h-full pt-4 mt-4 border-t border-cyan border-opacity-25 grid grid-cols-12 gap-2 overflow-y-auto no-scroll">
                    <div className='hidden fixed z-50 pt-32 xl:block col-span-2 overflow-x-auto'>
                        {
                            categories.map(category =>
                                <div className={`font-opensans relative text-white text-lg cursor-pointer pt-4 tracking-[4px] ${selectedCategory && selectedCategory.title === category.title && 'font-bold'}`} key={category.title} onClick={() => onCategoryClick(category)}>
                                    <span className='pr-2'>-</span>
                                    <span>{category.title}</span>
                                </div>
                            )
                        }
                    </div>
                    <div className="hidden xl:block xl:col-span-2"></div>
                    {showArticles()}
                </div>

            </div>
        </>
    );
}
