// React
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { truncateString } from "../helpers/truncateString.js";
// Lottie
import Lottie from "lottie-react";
// Lottie animations
import linesAnimation from "../animations/home-page-lines-layer.json";
import hexagonAnimation from "../animations/home-page.json";
import logoAnimation from "../animations/geometry-logo-animation.json";


const easeInOutCubic = function (x) {

    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}

export default function Home({ callback, callback2, animationDuration, metaTitle, metaDescription, metaKeywords }) {

    if (!animationDuration) animationDuration = 2000;

    const container = useRef()
    const lines = useRef();
    const hexagon = useRef();
    const logo = useRef();
    const enterButton = useRef();

    const navigate = useNavigate();
    navigate('/notebook');
    let animation;

    useEffect(() => {
        callback(false);
        callback2(true);
    }, []);

    const progessAnimation = function (endTime) {

        const progress = easeInOutCubic(Math.min(1, 1 - ((endTime - Date.now()) / animationDuration)));

        if (progress >= 1) {
            clearInterval(animation);
            callback2(false);
            navigate("/notebook");
        }

        const scale = 1 + (3 * progress);
        const opacity = (1 - progress) * 100;

        // lines
        lines.current.animationContainerRef.current.style.transform = `scale(${scale})`;
        lines.current.animationContainerRef.current.style.opacity = `${opacity}%`;

        // Hexagon
        hexagon.current.animationContainerRef.current.style.transform = `scale(${scale})`;
        hexagon.current.animationContainerRef.current.style.opacity = `${opacity}%`;

        // Logo
        const logoProgress = Math.max((progress - 0.25), 0);
        const logoAnimationDuaration = logo.current.getDuration(true);
        logo.current.goToAndStop(Math.round(logoAnimationDuaration * logoProgress), true);

        var logoWidth = logo.current.animationContainerRef.current.offsetWidth;
        var newProgress = Math.max((progress - 0.5) * 2, 0);

        if (document.documentElement.clientWidth < 1024) logo.current.animationContainerRef.current.style.transform = `translateX(${((logoWidth / 2) - (container.current.offsetWidth / 2)) * newProgress}px) translateY(${((logoWidth / 2) - (container.current.offsetHeight / 2)) * newProgress}px)`;
        else logo.current.animationContainerRef.current.style.transform = `translateX(${((logoWidth / 2) - (container.current.offsetWidth / 2)) * newProgress}px)`;
    }

    const startAnimation = function () {

        enterButton.current.disabled = true;
        enterButton.current.classList.add("fade-out");
        const endTime = Date.now() + animationDuration;
        animation = setInterval(() => progessAnimation(endTime), 10);
    }

    return (
        <>
            <Helmet prioritizeSeoTags>
                <title data-rh="true">{metaTitle ? metaTitle : "Home"} - Geometry</title>
                <meta name="description" content={truncateString(metaDescription ? metaDescription : "Building and investing in research-rich startups", 152)} data-rh="true" />
                <meta name="keywords" content={metaKeywords ? metaKeywords : "investing, research, web3, cryptography, defi, startup, scalability"} data-rh="true" />
            </Helmet>

            <div ref={container} className="relative w-full h-full overflow-visible fade-in">
                <div className="absolute w-full h-full lg:-top-20 flex justify-center items-center z-0">
                    <Lottie lottieRef={lines} className="w-auto h-full" animationData={linesAnimation} autoplay={true} loop={true} />
                </div>
                <div className="absolute w-full h-full lg:-top-20 flex justify-center items-center z-10">
                    <Lottie lottieRef={hexagon} className="w-auto h-4/6" animationData={hexagonAnimation} autoplay={true} loop={true} />
                </div>
                <div className="absolute w-full h-full lg:-top-20 flex justify-center items-center z-20">
                    <Lottie lottieRef={logo} className="w-20 h-20 lg:h-32 lg:w-32 2xl:h-40 2xl:w-40" animationData={logoAnimation} autoplay={false} loop={false} />
                </div>
                <div className="absolute w-full h-auto bottom-0 left-0 flex justify-center z-30">
                    <button ref={enterButton} className="flex-col items-center mb-4 group" onClick={startAnimation}>
                        <p className="mb-5 font-clarikageo text-xl font-semibold tracking-[13.2px] text-white group-hover:text-cyan uppercase transition-colors duration-500">Enter site</p>
                        <svg className="w-7 h-7 text-cyan mx-auto animate-bounce" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M1.553 6.776a.5.5 0 0 1 .67-.223L8 9.44l5.776-2.888a.5.5 0 1 1 .448.894l-6 3a.5.5 0 0 1-.448 0l-6-3a.5.5 0 0 1-.223-.67z" />
                        </svg>
                    </button>
                </div>
            </div>
        </>
    );
}
